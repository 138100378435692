import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Banner({ children, image, subtitle, title }) {
  return (
    <section id='banner' className='major Banner'>
      <div className='Banner-Image'>
        <GatsbyImage
          image={image}
          objectFit='cover'
          objectPosition='50% 50%'
          alt='Titelbild' />
      </div>
      <div className='inner'>
        <header className='major'>
          <h1>
            {title}
            <span>{subtitle}</span>
          </h1>
        </header>
        <div className='content'>
          {children}
          <ul className='actions'>
            <li>
              <a href='#one' className='button scrolly'>
                Zu den Reisen <span className='icon fa-chevron-down' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}