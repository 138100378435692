import { graphql, Link } from 'gatsby'
import { Seo } from 'gatsby-plugin-wpgraphql-seo'
import parse from 'html-react-parser'
import React from 'react'
import Banner from '../components/Banner'
import CurrentTravels from '../components/CurrentTravels'
import Layout from '../components/layout'
import Section from '../components/Section'
import Tile from '../components/Tile'

export default function IndexPage({ data: { page, travels, materials } }) {
  return (
    <Layout>
      <Seo post={page} />

      <Banner
        image={page.home.banner.image.localFile.childImageSharp.gatsbyImageData}
        title={page.home.banner.title}
        subtitle={page.home.banner.subtitle}
      >
        {parse(page.home.banner.content)}
      </Banner>

      <div id="main">
        <section id="one" className="tiles">
          {travels.edges.map(({ node }) => {
            const title = node.travelPreview?.title || node.title
            const subtitle = node.travelPreview?.subtitle || ''
            const accent = node.appearance.accent
            const categories = node.categories.nodes.map(node => node.slug)
            const slug = node.slug
            const description = node.travelPreview?.description || node.excerpt
            const image = node.travelPreview?.image || node.featuredImage?.node
            const isNotComingSoon = !categories.includes('coming-soon')

            return (
              <Tile
                key={slug}
                image={image?.localFile.childImageSharp.gatsbyImageData}
                accent={accent || "0"}
                title={title}
                subtitle={subtitle}
                description={description}
                to={isNotComingSoon && slug}
              />
            )
          })}
        </section>

        <Section
          id="rohstoffe"
          image={
            page.home.materials.image.localFile.childImageSharp.gatsbyImageData
          }
        >
          <header className="major">
            <h2>{page.home.materials.title}</h2>
            <p>{page.home.materials.description}</p>
          </header>
          <br />
          <ul className="actions">
            {materials.edges.map(({ node: { slug } }) => (
              <li key={slug}>
                <Link to={slug} className="button next">
                  {slug.charAt(0).toUpperCase() + slug.slice(1)}
                </Link>
              </li>
            ))}
          </ul>
        </Section>

        <CurrentTravels />
      </div>
    </Layout>
  )
}

// Calculated from HD font size by container max-height in em (font-size * max-height)
export const query = graphql`
  query HomePageQuery {
    page: wpPage(isFrontPage: { eq: true }) {
      home {
        banner {
          content
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          subtitle
          title
        }
        materials {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          description
          title
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }

    travels: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "reisen" } } } }
      }
      sort: { fields: travelPreview___position }
    ) {
      edges {
        node {
          appearance {
            accent
          }
          categories {
            nodes {
              slug
            }
          }
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          slug
          title
          travelPreview {
            description
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            subtitle
            title
          }
        }
      }
    }

    materials: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "rohstoffe" } } } }
      }
    ) {
      edges {
        node {
          slug
        }
      }
    }
  }
`
