import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import React from 'react'

export default function Tile({
  accent,
  description,
  image,
  title,
  to,
  subtitle,
}) {
  return (
    <article className={`Tile Tile--style-${accent}`}>
      <div className="Tile-Image">
        <GatsbyImage
          image={image}
          objectFit="cover"
          objectPosition="50% 50%"
          alt="Reise Titelbild"
        />
      </div>
      <header className="major Tile-Content">
        <h2>
          {title}
          <span>
            {subtitle} {!to && <i className="uppercase">– Coming Soon</i>}
          </span>
        </h2>
        <p>{parse(description)}</p>
      </header>
      {to && (
        <Link
          to={to}
          aria-label="Zur Reise"
          rel="noopener noreferrer"
          className="link primary"
        />
      )}
      {to && (
        <div className="Tile-Hover">
          <Link to={to} rel="noopener noreferrer" className="button special">
            Zur Reise klicken
          </Link>
        </div>
      )}
    </article>
  )
}
