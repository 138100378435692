import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Section({ image, children }) {
  return (
    <section className='Section'>
      <div className='Section__Image'>
        <GatsbyImage
          image={image}
          objectFit='cover'
          objectPosition='50% 50%'
          alt='Rohstoffe' />
      </div>
      <div className='Section__Content inner'>{children}</div>
    </section>
  )
}